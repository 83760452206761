<template>
  <div class="background">
    <div class="title">
      <p id="our">
        Where do we see ourselves
      </p>
      <h1>Our Mission</h1>
      <p>Our mission extends beyond providing software solutions. We strive to empower fashion businesses by bridging the gap between technology and creativity. Our commitment is to foster growth and sustainability in an ever-evolving industry. Through innovation, we aim to be the catalyst for positive change, supporting fashion enterprises in realizing their full potential and achieving lasting success.</p>
      <!-- <p>In the fashion industry, efficient order management, production control,<br class="br"> and inventory optimization are paramount. SPIM Fashion combines powerful<br class="br"> algorithms and microservices to streamline these processes and transform the way fashion<br class="br"> companies operate. By providing real-time information on market demand, SPIM Fashion<br class="br"> prevents overproduction and ensures that resources are allocated effectively.<br class="br"> It also facilitates seamless collaboration among teams, enabling quick identification <br class="br">of strengths and weaknesses, leading to continuous improvement in production quality.</p> -->
    </div>
    <div class="sewer">
      <img
        src="../../assets/sewer.svg"
        alt="sewer"
      >
    </div>
  </div>
</template>

<style scoped>
.background{
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.title h1{
  font-size: 36px;
  font-weight: 600;
  color: white;
}

.title p {
  font-size: 19px;
  font-weight: 400;
  color: #93a2b8;
  width: 79%;
}

#our{
  color: #FF274F
}

@media screen and (min-width: 1200px) and (max-width: 1600px){
  .br{
    display: none;
  }

  .background{
    display: flex; 
    gap: 2%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px){
  .background{
    width: 90%;
    margin: auto;
    display: flex;
    gap: 2%;
    margin-top: 5%;
  }   

  .sewer img {
    width: 100%;
  }

  .br{
    display: none;
  }

  .title{
    width: 60%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .background{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  .sewer img{
    width: 100%;
  }

  .sewer{
    width: 85%;
    margin: auto;
  }

  .br{
    display: none;
  }

  .title p{
    margin-bottom: 5%;
    width: 100%;
  }

  #our{
    margin: 4% 0% 3% 0%;
  }
}

@media screen and (max-width: 600px) {
  .background{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .sewer img{
    width: 100%;
  }

  #our{
    font-size: 12px;
  }

  .title h1{
    font-size: 18px;
  }

  .title p{
    font-size: 12px;
    width: 100%;
  }

  .br {
    display: none;
  }
}
</style>