<template>
  <div>
    <bestPartner />
    <companySpim />
    <whySpim />
    <customerWord />
    <faqSpim />
    <getInTouch />
  </div>
</template>

<script>
import bestPartner from "@/components/home/bestPartner.vue"
import companySpim from "@/components/home/companySpim.vue"
import whySpim from "@/components/home/whySpim.vue"
import customerWord from "@/components/home/customerWord.vue"
import faqSpim from "@/components/home/faqSpim.vue"
import getInTouch from "@/components/home/getInTouch.vue"

export default {
  metaInfo() {
    return {
      title: 'SpimApp',
      meta: [
        {
          name: 'description',
          content: 'Empower your business growth with SPIM, the ultimate solution for streamlining operations...'
        },
        {
          property: 'og:description',
          content: 'Empower your business growth with SPIM, the ultimate solution for streamlining operations...'
        }
      ]
    };
  },
  components: {
    bestPartner,
    companySpim,
    whySpim,
    customerWord,
    faqSpim,
    getInTouch,
  }
}
</script>