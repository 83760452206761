<template>
  <div class="background">
    <div class="inBack">
      <div class="header">
        <img
          src="../../assets/about.svg"
          alt="AboutSpim"
        >
      </div>
      <div class="content">
        <h1>About Us</h1>
        <p>
          Welcome to SPIM, your ultimate all-in-one solution for ERP, CRM, RPA, and AI. SPIM is designed to meet the diverse and unique needs of various industries, providing tools that simplify your operations, enhance productivity, and drive substantial growth. Our innovative SaaS platform integrates seamlessly into your business, offering a comprehensive suite of features to ensure your success. <br><br>
          Designed to be user-friendly, SPIM enables businesses to improve customer satisfaction and retention. <br><br>
          Our mission is to provide a SaaS platform that combines ERP, CRM, RPA, and AI, enabling businesses to organize their processes, enhance customer relationships, and achieve unparalleled operational excellence.
          Founded on the core principles of innovation, efficiency, and customer-centricity, SPIM is dedicated to delivering solutions that are customizable, scalable, and user-friendly. Whether you're a small business seeking growth or a large enterprise aiming for efficiency, SPIM is here to support your journey towards success and excellence. <br><br>
          Our technology and uses span across various sectors, including automotive processes, manufacturing management, and inventory management, making SPIM a versatile choice for businesses of all sizes. <br><br>
          <span style="font-weight: 700;">Why Choose SPIM?</span> <br><br>
          All-In-One Solutions
          SPIM offers a comprehensive suite of tools that cover ERP, CRM, RPA, and AI, providing a seamless and integrated experience for managing all aspects of your business operations. Our platform integrates cloud-based ERP and CRM software for small businesses, ensuring that you have the right tools to succeed. With SPIM, you can manage everything from activities and benefits to customer relationship management and marketing cloud. <br><br>
          Tailored to Your Needs
          Our platform is highly customizable, ensuring it meets the specific requirements of different industries and business sizes. SPIM adapts to your business, not the other way around. Whether you need manufacturing or order management, our solutions are designed to fit your unique needs. We offer examples and system definitions to help you understand how SPIM can be tailored to your specific requirements. <br><br>
          Easy to Use <br>
          Designed with user experience in mind, SPIM's intuitive interface makes it easy to navigate and utilize all features effectively. Even non-technical users can quickly master the platform. Our solution and tools are designed to be user-friendly, ensuring that you can focus on what matters most – growing your business. With SPIM, you get all in one place.<br><br>
          Scalable<br>
          As your business grows, SPIM scales with you, ensuring continuous support and efficiency. Our platform is built to handle increasing data and process volumes, adapting to your expanding needs. From low-cost ERP for small business to enterprise solutions, SPIM offers the scalability you need to succeed. Our cloud ERP for small businesses and cloud ERP systems for small businesses are designed to grow with your business, providing the flexibility and support you need. <br><br>
          <span style="font-style: italic;">Get things done with SPIM.</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.inBack {
  width: 80%;
  margin: auto;
  padding-top: 3%;
}
.header img {
  width: 100%;
  height: 100%;
}
.content {
  padding: 2% 0%;
  width: 80%;
  margin: auto;
}
.content h1, .content p {
  color: #D0D5DD;
}
.content h1 {
  margin-bottom: 2%;
}

@media screen and (max-width: 600px) {
  .content {
    width: 100%
  }
}
</style>