<template>
  <div class="background">
    <p>Join all the companies already growing with us</p>
    <div class="logos">
      <a href="">
        <img
          src="../../assets/DrenushaXharra.svg"
          alt="Drenusha Xharra"
        >
      </a>
      <a href="">
        <img
          src="../../assets/FjollaNila.svg"
          alt="Fjolla Nila"
        >
      </a>
      <a href="">
        <img
          src="../../assets/Walone.svg"
          alt="Walone"
        >
      </a>
      <a href="">
        <img
          src="../../assets/Sahitilogo.svg"
          alt="Valdrin Sahiti"
        >
      </a>
      <a href="">
        <img
          src="../../assets/DSlogo.svg"
          alt="DS Logistics"
        >
      </a>
    </div>
  </div>
</template>

<style scoped>
.background{
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0F1626;
  color: white;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.logos a {
  width: 30%;
  margin-bottom: 3%
}

.logos a img {
  width: 80%;
  height: 100%;
}

.background img{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0F1626;
  width: 80%;
}


.background p {
  color: #93a2b8;
  font-size: 20px;
  font-weight: 400;
  width: 60%;
  margin-bottom: 2%;
  text-align: center;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .logos a {
    width: 45%
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){

  .background {
    height: 400px !important;
  }

  .logos a {
    width: 30%;
    margin-bottom: 5%;
  }

  .logos {
    width: 80%;
  }

  .background p {
    font-size: 16px;
    width: 100%;
  }
}

@media screen and (max-width: 600px){
  .logos {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
  }

  .logos a {
    width: 40%;
    margin-bottom: 5%;
  }

  .background {
    height: 400px;
  }

  .background p {
    font-size: 14px;
    width: 100%;
  }
}
</style>