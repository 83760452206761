<template>
  <div>
    <AboutUs />
    <faqSpim />
  </div>
</template>

<script>
import AboutUs from '@/components/aboutSpim/AboutUs.vue';
import faqSpim from '@/components/home/faqSpim.vue'

export default {
  name: "AboutSpim",
  metaInfo() {
    return {
      title: "About SPIM - Learn About Our Vision, Mission, and Team",
      metaTags: [
      { name: "description", content: "Learn about SPIM's vision, mission, and the dedicated team working to revolutionize business operations. Discover our story, values, and commitment to innovation and excellence in providing business solutions." },
        { property: "og:description", content: "Learn about SPIM's vision, mission, and the dedicated team working to revolutionize business operations. Discover our story, values, and commitment to innovation and excellence in providing business solutions." }
      ]
    }
  },
  components: { AboutUs, faqSpim }
}
</script>

<style>

</style>