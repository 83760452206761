<template>
  <div class="background">
    <div class="content">
      <img
        src="../../assets/AvatarGroup.svg"
        alt=""
      >
      <p>
        Can’t find what you’re looking for?
        Get in touch with our dedicated support team. We are here to assist you.
      </p>
      <button id="touch">
        <a
          href="/contact"
          style="text-decoration: none; color: white;"
        >Get in touch</a>
      </button>
    </div>
  </div>
</template>

<style scoped>

.background{
  width: 100%;
}
.content{
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
  background: #0F1626;
  padding: 3% 0%;
}

.content img {
  width: 8%;
  height: 100%
}

.background p {
  font-size: 18px;
  font-weight: 400;
  color: #93a2b8;
  margin-top: 3%;
  background: #0F1626;
  margin-bottom: 3%;
}

#touch{
  color: white;
  width: 135px;
  height: 48px;
  background: #0041E8;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  border: none;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .content{
    width: 90%;
  }

  .background p{
    margin: 6% 0% 5% 0%;
  }
}

@media screen and (max-width: 600px){

  .content{
    width: 90%;
  }
  .background p{
    font-size: 14px;
    margin: 15% 0% 10% 0%;
  }
}
</style>