<template>
  <div class="background">
    <div class="navbar">
      <Slide
        :close-on-navigation="true"
        class="burger-menu custom-burger-menu"
      >
        <div class="burger-nav">
          <router-link
            class="fash"
            to="/spim-fashion"
          >
            <span id="fashionM">SPIM Fashion</span>
          </router-link>
          <router-link
            to="/spim-logistics"
            class="fash"
          >
            <span id="logisticsM">SPIM Logistics</span>
          </router-link>
        </div>
      </Slide>
      <div class="logo">
        <a href="/">
          <img
            src="../../assets/SpimLogo.svg"
            alt="Spim Logo"
          >
        </a>
      </div>
      <div class="buttons">
        <a href="/spim-fashion">
          <button id="fashion">
            SPIM Fashion
          </button>
        </a>
        <a href="/spim-logistics">
          <button id="logistics">
            SPIM Logistics
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Slide } from 'vue3-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
    components: {
        Slide // Register your component
    }
}
</script>

<style scoped>

.background{
  background: #0C111D;
}

.logo a img { 
  width: 100%;
  height: 100%
}

.burger-menu {
  display: none;
}
.navbar{
  display: flex;
  justify-content: space-between;
  margin: auto;
  height: 100px;
  width: 80%;
}

#fashion{
  background: #FF274F;
  width: 173px;
  height: 50px;
  border-radius: 6px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
}


#logistics{
  background: #55B94A;
  color: white;
  width: 173px;
  height: 50px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  border: none;  
}

.buttons{
  display: flex;
  gap: 5%;
}


@media screen and (min-width: 600px) and (max-width: 1000px) {
  .logo{
  width: 65% !important;
}

.logo a img{
  width: 38% !important;
}
}

@media screen and (max-width: 1000px){
  .burger-menu {
    display: block;
  }

  .buttons{
    display: none;
  }
  .navbar {
    display: flex;
    margin: auto;
    height: 100px;
    width: 100%;
}

.logo{
  width: 70%;
}

.logo a img{
  width: 45%;
}

.burger-nav{
  background: none;
  display: flex;
  flex-direction: column;
}

.burger-nav #fashionM{
  color: #FF274F;
  text-decoration: none;
  font-size: 25px;
  font-weight: 600;
}

.burger-nav #logisticsM{
  color: #55B94A;
  text-decoration: none;
  font-size: 25px;
  font-weight: 600;
}

  .fash{
    text-decoration: none;
  }
}


</style>