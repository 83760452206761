<template>
  <div class="background">
    <div class="solution">
      <p style="color: #FF274F">
        Solution
      </p>
      <!-- <h1>An seamless experience for companies</h1>
      <p>Treat candidates with a rich careers site and a wonderful application process.</p> -->
    </div>
    <div class="share">
      <div class="team">
        <h3>Inventory Management</h3>
        <p>Track stock levels, monitor product movement, and optimize stocking patterns for improved supply chain efficiency.</p>
        <h3>Order Tracking</h3>
        <p>From order creation to delivery, SPIM ensures transparency and accuracy, reducing errors and enhancing customer satisfaction.</p>
        <h3>Vendor Collaboration</h3>
        <p>SPIM facilitates effective interaction, automating order placements, and ensuring timely deliveries through integrated vendor collaboration tools.</p>
        <h3>Trend Analysis</h3>
        <p>SPIM incorporates advanced analytics tools to help you analyze market trends, enabling better-informed decisions and the ability to capitalize on emerging styles.</p>
        <h3>Sales Forecasting</h3>
        <p>Leverage historical data, market trends, and customer behavior analysis to optimize inventory levels and maximize revenue.</p>
        <h3>Custom Reporting</h3>
        <p>Generate insightful reports on inventory turnover, sales performance, and trend analyses.</p>
      </div>
      <div>
        <img
          src="../../assets/fashionlogin.svg"
          alt="mockup"
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.background{
  width: 80%;
  margin: auto;
  margin-bottom: 5%;
}

.share{
  display: flex;
  justify-content: space-between;
  gap: 5%;
}

.share img{
  width: 100%;
}

.solution{
  text-align: left;
  margin-bottom: 5%;
}

.solution h1{
  color: white;
  font-size: 36px;
  font-weight: 600;
}

.solution p{
  color: #475467;
  font-size: 20px;
  font-weight: 400;
}

.share h3{
  color: white;
  font-size: 21px;
  font-weight: 600;
}

.share p{
  color: #93a2b8;
  font-size: 18px;
  font-weight: 400;
}

@media screen and (min-width: 600px) and (max-width: 1000px){

  .background{
    width: 85%;
  }
  .share{
    display: flex;
    flex-direction: column;
    gap: 5%;
  }
}

@media screen and (max-width: 600px) {

  .background{
    width: 85%;
  }
  .share{
    display: flex;
    flex-direction: column;
  }

  .solution h1{
    font-size: 18px;
  }

  .solution p {
    font-size: 12px;
  }

  .team h3{
    font-size: 18px;
  }

  .team p{
    font-size: 12px;
  }

}
</style>