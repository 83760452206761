<template>
  <div class="background">
    <div class="whyspim">
      <div class="title">
        <p style="color:#0041E8; font-size: 16px">
          Features
        </p>
        <h1>Where is SPIM applicable?</h1>
        <p>SPIM is applicable across all industries, regardless of size or hierarchical complexity.</p>
      </div>
      <div class="services">
        <div
          class="card"
        >
          <img
            src="../../assets/Icon2.svg"
            class="card-img-top"
            alt="icon"
          >
          <div class="card-body">
            <h5 class="card-title">
              Customizable Dashboard
            </h5>
            <p class="card-text">
              Users can personalize their dashboard with widgets, charts, and key performance indicators (KPIs) based on their specific needs. Drag-and-drop interface for rearranging and resizing elements.
            </p>
          </div>
        </div>

        <div
          class="card"
        >
          <img
            src="../../assets/Icon3.svg"
            class="card-img-top"
            alt="icon"
          >
          <div class="card-body">
            <h5 class="card-title">
              Report Management  
            </h5>
            <p class="card-text">
              Users can create and save custom reports with specific parameters. Ability to export reports in various formats (PDF, Excel, CSV) for easy sharing and analysis. Automated scheduling for regular report generation and delivery.
            </p>
          </div>
        </div>

        <div
          class="card"
        >
          <img
            src="../../assets/Icon1.svg"
            class="card-img-top"
            alt="icon"
          >
          <div class="card-body">
            <h5 class="card-title">
              Task Management
            </h5>
            <p class="card-text">
              Link tasks together to show dependencies and ensure a smooth workflow. Assign priorities to tasks for better time management.
            </p>
          </div>
        </div>

        <div
          class="card"
        >
          <img
            src="../../assets/Icon4.svg"
            class="card-img-top"
            alt="icon"
          >
          <div class="card-body">
            <h5 class="card-title">
              Employee Information
            </h5>
            <p class="card-text">
              Maintain comprehensive profiles for each employee, including contact details, job roles, and performance metrics. Record and analyze employee attendance with customizable tracking options. Track and manage employees' skills, certifications, and training records.
            </p>
          </div>
        </div>

        <div
          class="card"
        >
          <img
            src="../../assets/Icon5.svg"
            class="card-img-top"
            alt="icon"
          >
          <div class="card-body">
            <h5 class="card-title">
              Integration Capabilities
            </h5>
            <p class="card-text">
              Allow integration with third-party tools and services to enhance functionality. Provide pre-built integrations with popular business apps for seamless connectivity.
            </p>
          </div>
        </div>

        <div
          class="card"
        >
          <img
            src="../../assets/Icon6.svg"
            class="card-img-top"
            alt="icon"
          >
          <div class="card-body">
            <h5 class="card-title">
              Access Control and Permissions
            </h5>
            <p class="card-text">
              Define different user roles with specific permissions to control data access. Allow administrators to tailor access levels according to organizational hierarchy. 
            </p>
          </div>
        </div>

        <div
          class="card"
        >
          <img
            src="../../assets/Icon6.svg"
            class="card-img-top"
            alt="icon"
          >
          <div class="card-body">
            <h5 class="card-title">
              Notification System
            </h5>
            <p class="card-text">
              Enable users to set notifications for specific events and deadlines. Choose preferred channels for receiving updates.
            </p>
          </div>
        </div>

        <div
          class="card"
        >
          <img
            src="../../assets/Icon6.svg"
            class="card-img-top"
            alt="icon"
          >
          <div class="card-body">
            <h5 class="card-title">
              Data Security and Compliance
            </h5>
            <p class="card-text">
              Implement strong encryption protocols for data security. Provide features to adhere to industry-specific regulations and standards.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.background{
  width: 100%;
}

.whyspim{
  width: 80%;
  margin: auto;
  margin-top: 5%;
}

.title{
  text-align: center;
  color: white;
  margin-bottom: 5%;
}

.title h1{
  font-size: 36px;
  font-weight: 600;
}

.title p{
  color: #93a2b8;
  font-size: 20px;
  font-weight: 400;
}

.services{
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 5%;
  margin: auto;
}

.card{
  border: none;
  width: 23rem;
  margin-bottom: 8%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #0C111D;
}

.card-img-top{
  width: 20%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: white;
}

.card-text{
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #93a2b8;
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .card{
    width: 18rem;
  }
  .card-img-top{
    width: 20%;
    height: 20%;
    padding: 2% 0%;
  }
}

@media screen and (max-width: 600px){

.title{
  margin: 20% 0%;
}
.title h1 {
  font-size: 18px;
}

.title p {
  font-size: 13px;
}

.card-img-top{
  width: 25%;
  height: 25%;
  padding: 2% 0%;
}

.card-title{
  font-size: 18px;
}

.card-text{
  font-size: 12px;
}
}
</style>