<template>
  <div class="background">
    <div class="title">
      <h1>Frequently asked questions</h1>
      <p>Everything you need to know about SPIM.</p>
    </div>
    <div
      id="accordionFlushExample"
      class="accordion accordion-flush"
    >
      <div class="accordion-item">
        <h2
          id="flush-headingOne"
          class="accordion-header"
        >
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Which team members can use it?
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            SPIM is designed for all team members, from executives to frontline employees. Tailor access levels based on roles for a customized experience.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2
          id="flush-headingTwo"
          class="accordion-header"
        >
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo"
          >
            What plans can we have?
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            Choose from a variety of flexible plans to suit your business needs. We offer plans for small businesses and enterprises alike.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2
          id="flush-headingThree"
          class="accordion-header"
        >
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree"
            aria-expanded="false"
            aria-controls="flush-collapseThree"
          >
            Can all company departments use it?
          </button>
        </h2>
        <div
          id="flush-collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            Absolutely! SPIM is designed to be versatile, catering to the unique needs of different departments within your organization.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2
          id="flush-headingFour"
          class="accordion-header"
        >
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour"
            aria-expanded="false"
            aria-controls="flush-collapseFour"
          >
            Will I get help to get around using it?
          </button>
        </h2>
        <div
          id="flush-collapseFour"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingFour"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            We will provide comprehensive guides for new users to learn how to use SPIM for the first time. We will also offer responsive customer support through multiple channels, whenever needed.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2
          id="flush-headingFive"
          class="accordion-header"
        >
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFive"
            aria-expanded="false"
            aria-controls="flush-collapseFive"
          >
            Is my data safe?
          </button>
        </h2>
        <div
          id="flush-collapseFive"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingFive"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            Your data security is our top priority. SPIM employs industry-standard encryption and security protocols to ensure the safety and confidentiality of your information.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background{
  width: 100%;
  margin: auto;
  margin-top: 5%;
}

.title{
  text-align: center;
}

.title h1{
  color: white;
  font-size: 36px;
  font-weight: 600;
}

.title p{
  color: #93a2b8;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5%;
}

.accordion-item{
  width: 45%;
  margin: auto;
  padding: 1% 0%;
}

.accordion-item, .accordion-button{
  background: #0C111D;
  color: white;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .accordion-item{
    width: 85%;
  }
}

@media screen and (max-width: 600px){

  .title{
    margin: 10% 0%;
  }
  .title h1{
    font-size: 18px;
  }

  .title p{
    font-size: 10px;
  }

  .accordion-item{
    width: 91%;
  }
}
</style>