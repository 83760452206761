<template>
  <div>
    <supplyChain />
    <solutionLogistic />
    <customerLogistic />
    <logMission />
    <getInTouchLog />
  </div>
</template>

<script>
import supplyChain from "@/components/spimLogistics/supplyChain.vue"
import solutionLogistic from "@/components/spimLogistics/solutionLogistic.vue"
import customerLogistic from "@/components/spimLogistics/customerLogistic.vue"
import logMission from "@/components/spimLogistics/logMission.vue"
import getInTouchLog from "@/components/spimLogistics/getInTouchLog.vue"

export default {
  name: "SpimLogistics",
  metaInfo() {
    return {
      title: "SPIM Logistics Solutions: Shipment Tracking, Warehouse Management, Route Optimization, and More",
      metaTags: [
        { name: "description", content: "Discover SPIM Logistics solutions for efficient logistics management. Track shipments in real-time, manage warehouses effectively, optimize delivery routes, forecast demand, analyze real-time analytics, and collaborate with suppliers seamlessly. Learn how SPIM Logistics revolutionizes logistics management with innovation, sustainability, and efficiency." },
        { property: "og:description", content: "Discover SPIM Logistics solutions for efficient logistics management. Track shipments in real-time, manage warehouses effectively, optimize delivery routes, forecast demand, analyze real-time analytics, and collaborate with suppliers seamlessly. Learn how SPIM Logistics revolutionizes logistics management with innovation, sustainability, and efficiency." }
      ]
    }
  },
  components: {
    supplyChain,
    solutionLogistic,
    customerLogistic,
    logMission,
    getInTouchLog,
  }
}
</script>