<template>
  <div class="background">
    <div class="customer">
      <img
        src="../../assets/DSlogo.svg"
        alt="logo"
      >
      <h1>DigIT's SPIM Logistic solution has optimized our logistics, enhancing efficiency and customer experience.</h1>
      <p>DS Logistik</p>
    </div>
  </div>
</template>

<style scoped>
.background{
  width: 100%;
  margin: auto;
  background-color: #0F1626;
}

.customer{
  width: 70%;
  margin: auto;
  text-align: center;
  background-color: #0F1626;
  padding: 5% 0%;
}

.customer img {
  width: 15%;
}

.customer h1{
  font-size: 35px;
  font-weight: 500;
  color: white;
  margin: 3% 0%;
  background-color: #0F1626;
}

.customer p{
  font-size: 16px;
  font-weight: 400;
  color: #93a2b8;
  margin-top: 1%;
  background-color: #0F1626;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .customer h1{
    font-size: 30px;
  }
  .customer img {
    width: 30%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .customer h1{
    font-size: 25px;
  }

  .customer{
    width: 90%;
  }
  .customer img{
    padding: 3% 0%;
    width: 35%;
  }
}

@media screen and (max-width: 600px){
  .customer h1{
    font-size: 15px;
  }

  .customer{
    width: 95%;
  }

  .customer img{
    padding: 2% 0% 5% 0%;
    width: 38%;
  }
}
</style>