<template>
  <div class="background">
    <Splide
      :options="options"
      aria-label="My Favorite Images"
    >
      <SplideSlide>
        <div class="customer">
          <img
            src="../../assets/Sahitilogo.svg"
            alt="logo"
          >
          <h1>SPIM allows us to effectively track and manage inventory, preventing duplicate orders and ensuring optimal utilization of our resources, leading to increased productivity and profitability.</h1>
          <p>Valdrin Sahiti, CEO</p>
        </div>
      </SplideSlide>

      <SplideSlide>
        <div class="customer">
          <img
            src="../../assets/DSlogo.svg"
            alt="logo"
          >
          <h1>DigIT's SPIM Logistic solution has optimized our logistics, enhancing efficiency and customer experience.</h1>
          <p>DS Logistik</p>
        </div>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import '@splidejs/vue-splide/css';

// or other themes
import '@splidejs/vue-splide/css/skyblue';
import '@splidejs/vue-splide/css/sea-green';

// or only core styles
import '@splidejs/vue-splide/css/core';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default ( {
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return{
      shouldShowSlider: false,
      options: {
        type   : 'loop',
            pagination:false,
            noDrag:true,
            rewind: true,
            autoplay: true,
            arrows:true,
            infinite: true,
            interval: 5000,
            perPage: 1,
            perMove: 1,
            dots:false,
            breakpoints: {
              1650: {
                
            perPage: 1,
            arrows:false,
          },
              1240: {
            perPage: 1,
            arrows:false,
            autoplay: true,
          },
              800: {
                type   : 'loop',
            perPage: 1,
            perMove: 1,
            arrows:false,
            autoplay: true,
          },
          500: {
            type   : 'loop',
            autoplay: true,
            perPage: 1,
            arrows:false,
          },
        },
          
      },
      options2: {
        type   : 'slide',
            pagination:false,
            noDrag:true,
            rewind: true,
            autoplay: true,
            arrows:false,
            infinite: true,
            interval: 3000,
            perPage: 3,
            perMove: 1,
            dots:false,
            breakpoints: {
              1650: {
                
            perPage: 3,
            arrows:false,
          },
              1240: {
            perPage: 3,
            arrows:false,
            autoplay: true,
          },
              801: {
            perPage: 2,
            perMove: 1,
            arrows:false,
            autoplay: true,
          },
          500: {
            type   : 'loop',
            autoplay: true,
            perPage: 1,
            arrows:false,
          },
        },
          
      }
    }
  },
} );
</script>

<style scoped>
.background{
  width: 100%;
  margin: auto;
  background-color: #0F1626;
}

.customer{
  width: 70%;
  margin: auto;
  text-align: center;
  background-color: #0F1626;
  padding: 5% 0%;
}

.customer h1{
  font-size: 35px;
  font-weight: 500;
  color: white;
  margin: 3% 0%;
  background-color: #0F1626;
}

.customer img {
  width: 15%;
  height: 100%;
}

.customer p{
  font-size: 16px;
  font-weight: 400;
  color: #93a2b8;
  margin-top: 1%;
  background-color: #0F1626;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .customer h1{
    font-size: 30px;
  }
  .customer img {
    width: 30%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .customer h1{
    font-size: 25px;
  }

  .customer{
    width: 90%;
  }
  .customer img{
    padding: 3% 0%;
    width: 35%;
  }
}

@media screen and (max-width: 600px){
  .customer h1{
    font-size: 15px;
  }

  .customer{
    width: 95%;
  }

  .customer img{
    padding: 2% 0% 5% 0%;
    width: 38%;
  }
}
</style>