<template>
  <div class="background">
    <div class="title">
      <p id="our">
        Where do we see ourselves
      </p>
      <h1>Our Mission</h1>
      <p>SPIM Logistics is committed to revolutionizing logistics management. Our mission extends beyond providing software solutions; we aim to equip businesses with the essential tools needed to thrive in a dynamic and interconnected world. By embracing innovation, sustainability, and efficiency, we strive to be the driving force behind positive transformations in the logistics industry, empowering businesses to navigate challenges and achieve unparalleled success.</p>
      <!-- <p>For businesses operating in the logistics sector, SPIM Logistics<br class="br"> offers a comprehensive solution to optimize supply chain management.<br class="br"> With automated supplier ordering, SPIM Logistics minimizes mistakes in <br class="br">inventory procurement, reducing costs and ensuring timely delivery.<br class="br"> By leveraging SPIM's microservices and algorithms, logistics companies<br class="br"> gain a holistic view of their inventory, enabling them to make informed<br class="br"> decisions and enhance overall efficiency. SPIM Logistics empowers businesses<br class="br"> to address communication gaps, providing on-demand access to critical <br class="br">information and improving coordination among stakeholders.</p> -->
    </div>
    <div class="sewer">
      <img
        src="../../assets/missionLog.svg"
        alt="sewer"
      >
    </div>
  </div>
</template>

<style scoped>
.background{
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 3%;
}

.title h1{
  font-size: 36px;
  font-weight: 600;
  color: white;
}

.title p {
  font-size: 19px;
  font-weight: 400;
  color: #93a2b8;
  width: 79%;
}

#our {
  color: #55B94A
}

@media screen and (min-width: 1200px) and (max-width: 1600px){
  .br{
    display: none;
  }

  .background{
    display: flex; 
    gap: 2%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px){
  .background{
    width: 90%;
    margin: auto;
    display: flex;
    gap: 2%;
    margin-top: 5%;
  }   

  .sewer img {
    width: 100%;
  }

  .br{
    display: none;
  }

  .title{
    width: 60%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .background{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  .sewer img{
    width: 100%;
  }

  .sewer{
    width: 85%;
    margin: auto;
  }

  .br{
    display: none;
  }

  .title p{
    margin-bottom: 5%;
    width: 100%;
  }

  #our{
    margin: 4% 0% 3% 0%;
  }
}

@media screen and (max-width: 600px) {
  .background{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .sewer img{
    width: 100%;
  }

  #our{
    font-size: 12px;
  }

  .title h1{
    font-size: 18px;
  }

  .title p{
    font-size: 12px;
    width: 100%;
  }

  .br {
    display: none;
  }
}
</style>