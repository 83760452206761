<template>
  <div class="background">
    <div class="solution">
      <p style="color: #55B94A">
        Solution
      </p>
      <!-- <h1>An seamless experience for companies</h1>
      <p>Treat candidates with a rich careers site and a wonderful application process.</p> -->
    </div>
    <div class="share">
      <div class="team">
        <h3>Shipment Tracking</h3>
        <p>Offers real-time tracking capabilities, allowing you to monitor and trace shipments at every stage.</p>
        <h3>Warehouse Management</h3>
        <p>Efficiently manage inventory, streamline picking and packing processes, and enhance order fulfillment accuracy to meet the demands of an evolving market.</p>
        <h3>Route Optimization</h3>
        <p>Advanced algorithms to optimize delivery routes, reducing transportation costs and improving delivery timeframes.</p>
        <h3>Demand Forecasting</h3>
        <p>Leverage historical data and real-time analytics to make data-driven decisions, ensuring that your inventory aligns with actual market needs.</p>
        <h3>Real-time Analytics</h3>
        <p>Comprehensive analytics, enabling you to identify bottlenecks, optimize processes, and enhance overall supply chain performance.</p>
        <h3>Supplier Collaboration</h3>
        <p>Share real-time information, automate procurement processes, and ensure a seamless flow of goods through an interconnected network.</p>
      </div>
      <div>
        <img
          src="../../assets/logisticlogin.svg"
          alt="mockup"
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.background{
  width: 80%;
  margin: auto;
  margin-bottom: 5%;
}

.share{
  display: flex;
  justify-content: space-between;
  gap: 5%;
}

.share img{
  width: 100%;
}

.solution{
  text-align: left;
  margin-bottom: 5%;
}

.solution h1{
  color: white;
  font-size: 36px;
  font-weight: 600;
}

.solution p{
  color: #475467;
  font-size: 20px;
  font-weight: 400;
}

.share h3{
  color: white;
  font-size: 21px;
  font-weight: 600;
}

.share p{
  color: #93a2b8;
  font-size: 18px;
  font-weight: 400;
}

@media screen and (min-width: 600px) and (max-width: 1000px){

.background{
  width: 85%;
}
.share{
  display: flex;
  flex-direction: column;
  gap: 5%;
}
}

@media screen and (max-width: 600px) {

.background{
  width: 85%;
}
.share{
  display: flex;
  flex-direction: column;
}

.solution h1{
  font-size: 18px;
}

.solution p {
  font-size: 12px;
}

.team h3{
  font-size: 18px;
}

.team p{
  font-size: 12px;
}

}
</style>