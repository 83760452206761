<template>
  <div class="background">
    <div class="footer">
      <footer>
        <div class="cont">
          <div class="logo">
            <a href="/">
              <img
                src="../../assets/SpimLogo.svg"
                alt="Spim Logo"
              >
            </a>
          </div>

          <div class="links">
            <b>QUICK LINKS</b>
            <a href="/"><p>Home</p></a>
            <a href="/"><p>Services</p></a>
            <a href="/about-spim"><p>About SPIM</p></a>
            <a href="/contact"><p>Contact</p></a>
          </div>

          <div class="links">
            <b>SERVICES</b>
            <p>SaaS</p>
            <p>CRM</p>
            <p>ERP</p>
            <p>RPA</p>
          </div>

          <div class="links">
            <b>PRODUCT</b>
            <a href="/spim-fashion"><p>SPIM Fashion</p></a>
            <a href="/spim-logistics"><p>SPIM Logistic</p></a>
          </div>

          <div class="socialMedia">
            <a
              href="https://www.facebook.com/people/SPIMapp/61556281599082/"
              target="_blank"
            >
              <img
                src="../../assets/Facebook.svg"
                alt="facebook"
              >
            </a>
            <a
              href="https://www.linkedin.com/company/spim-app"
              target="_blank"
            >
              <img
                src="../../assets/linkedin.svg"
                alt="linkedin"
              >
            </a>
            <a
              href="https://www.instagram.com/spim.app/?hl=en"
              target="_blank"
            >
              <img
                src="../../assets/Instagram.svg"
                alt="instagram"
              >
            </a>
          </div>
        </div>
      </footer>
    </div>
    <hr
      style="border: 1px solid #d8d8d9; width: 80%; margin: auto; background: #0F1626;"
    >
    <div class="copyright">
      <p>
        © 2024 - SPIM powered by <a
          href="https://www.digitbusiness.ch/"
          target="blank"
        >DigIT Consulting GmbH</a>
      </p>
    </div>
  </div>
</template>

<style scoped>

*{
  background: #0F1626;
}

.footer{
  height: 400px;
  display: flex;
}
footer{
  display: flex;
  width: 100%;
}

footer p{
  color: #838E9E;
  font-weight: 500;
  font-size: 16px;
}

.logo{
  width: 20%;
}

.logo img{
  margin-bottom: 7%;
}

.socialMedia{
  display: flex;
  flex-direction: column;
  width: 2%;
}

.socialMedia a img{
  width: 100%;
  height: 100%;
  margin-bottom: 35%;
}

.copyright{
  display: flex;
  justify-content: center;
}

.copyright p, .copyright a{
  color: white;
  text-decoration: none;
}

.cont{
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-between;
  align-items: flex-start;
}

.links a{
  text-decoration: none;
}

.links b{
  color: white;
}

.socialMedia a{
    width: 100%;
  }

  .socialMedia img{
    width: 100%;
    margin-bottom: 20%;
  }

  @media screen and (min-width: 1000px) and (max-width: 1400px){
    .socialMedia{
      display: flex;
      flex-direction: column;
      width: 3%;
    }
  }

@media screen and (min-width: 600px) and (max-width: 1000px){
  .cont{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer{
    height: fit-content;
  }

  .logo p{
    display: none;
  }

  .socialMedia{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3%;
    gap: 2%;
    width: 70%;
  }

  .logo{
    width: 30%;
    margin-bottom: 8%;
    padding-top: 8%;
  }

  .logo img{
    width: 100%;
  }

  .links{
    text-align: center;
    margin-bottom: 5%;
  }

  .socialMedia img{
    margin-bottom: 7%;
  }

  .socialMedia a{
    width: 5%;
  }

  .socialMedia img{
    width: 100%;
  }
}

@media screen and (max-width: 600px){
  .cont{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer{
    height: fit-content;
  }

  .logo p{
    display: none;
  }

  .logo{
    width: 50%;
    margin-bottom: 12%;
    padding-top: 20%;
  }

  .logo img{
    width: 100%;
  }

  .links{
    text-align: center;
    margin-bottom: 5%;
  }

  .socialMedia img{
    margin-bottom: 7%;
    margin-right: 10%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .socialMedia a{
    width: 10%;
  }

  .socialMedia{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 5%;
    width: 75%;
  }
}


</style>