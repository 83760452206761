<template>
  <div>
    <ContactForm />
  </div>
</template>

<script>
import ContactForm from '../components/contact/ContactForm.vue';

export default {
  name: "ContactUs",
  metaInfo() {
    return {
      title: "Contact SPIM - Get in Touch with Our Support Team",
      metaTags: [
        { name: "description", content: "Get in touch with SPIM's support team for inquiries, feedback, or assistance. Our experts are ready to help you with your business needs and provide the best solutions to streamline your operations. Contact us today!" },
        { property: "og:description", content: "Get in touch with SPIM's support team for inquiries, feedback, or assistance. Our experts are ready to help you with your business needs and provide the best solutions to streamline your operations. Contact us today!" }
      ]
    }
  },
  components: {
    ContactForm
  }
}
</script>

<style>

</style>