<template>
  <div class="background">
    <div class="content">
      <div class="title">
        <h1>SPIM Fashion Transforming the<br class="br"> Fashion Industry</h1>
        <p>SPIM Fashion combines powerful algorithms and microservices to streamline<br class="br"> these processes and transform the way fashion companies operate.</p>
        <div class="call">
          <a
            href="/contact"
          ><button id="call">Schedule a Call <img
            src="../../assets/call-me-hand.svg"
            alt=""
          ></button></a>
        </div>
      </div>
      <div class="spim">
        <img
          src="../../assets/fashiondashboard.svg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<style scoped>

*{
  background-image: url('../../assets/BackgroundPink.svg');
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: bottom;
}

.background{
  width: 100%;
}
.content{
  width: 80%;
  margin: auto;
  padding-top: 8%;
  margin-bottom: 5%;
}

.title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
}

.title h1{
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 1%;
}

.title p{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 4%;
}

.call{
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
}

.call button{
  color: white;
  background: #FF274F;
  width: 210px;
  height: 55px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
}

#call img{
  width: 7%;
  background-color: #FF274F;
}

.call a{
  text-decoration: none;
}

.spim{
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1000px) and (max-width: 1200px){
  .br{
    display: none;
  }

  .spim img{
    width: 90%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){

  .content{
    width: 85%;
  }
  .title h1{
    font-size: 50px;
    margin-bottom: 5%;
  }

  .title p{
    font-size: 20px;
  }
  .br {
    display: none;
  }
  .spim img{
    width: 100%;
  }
}


@media screen and (max-width: 600px) {
  .spim img{
    width: 100%;
  }

  .spim{
    width: 100%;
  }

  .title h1{
    font-size: 18px;
    margin-bottom: 5%;
  }

  .title p{
    font-size: 12px;
  }

  .call button{
  width: 168px;
  height: 40px;
  margin: 8% 0%;
}
}
</style>