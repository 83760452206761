<template>
  <div class="background">
    <div class="contact">
      <div class="contactform">
        <h2>Get in touch</h2>
        <p>Our team would love to hear from you.</p>
        <div class="form">
          <div class="nameEmail">
            <div class="name">
              <label
                class="label"
                for="full-name"
              >First name</label>
              <input 
                id="full-name"
                v-model="firstName"
                type="text"
                placeholder="First name"
                required
              >
            </div>
            <div class="emailIn">
              <label
                class="label"
                for="email"
              >Last name</label>
              <input
                id="lastname"
                v-model="lastName"
                type="text"
                placeholder="Last name"
                required
              >
            </div>
          </div>
          <label
            class=" label"
            for="email"
          >{{ $t('Email') }}</label>
          <input
            id="email"
            v-model="email"
            type="text"
            placeholder="Email"
            required
          >
          <label
            v-if="showDescription"
            class=" label"
            for="company"
          >Company/product name</label>
          <input
            v-if="showDescription"
            id="company"
            v-model="company"
            type="text"
            placeholder="Product name"
            required
          >
          <label
            class=" label"
            for="message"
          >{{ $t('Message') }}</label>
          <textarea
            id="message"
            v-model="message"
            type="text"
            required
          />
          <div class="submit">
            <button @click="submitForm">
              Send message
            </button>
          </div>
        </div>
      </div>
      <div class="contactimage">
        <img
          src="../../assets/contact.svg"
          alt="contact"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { AWSurl } from "@/domainConfig";
import AWS from "aws-sdk";

export default {
  data() {
    return {
      firstName: "",
      email: "",
      lastName: "",
      company: "",
      message: "",
      showDescription: true
    }
  },
  methods: {
    async submitForm() {
      const ses = new AWS.SES({
        accessKeyId: AWSurl.accessKeyId,
        secretAccessKey: AWSurl.secretAccessKey,
        region: AWSurl.region,
      });

      const params = {
        Destination: {
          ToAddresses: ["contacts@digitbusiness.ch"],
        },
        Message: {
          Body: {
            Text: {
              Data: `FirstName: ${this.firstName}
              Email: ${this.email}
              LastName: ${this.lastName}
              Company: ${this.company}
              Message: ${this.message}`,
            },
          },
          Subject: {
            Data: "Form submission from Spim.app website",
          },
        },
        Source: "contacts@digitbusiness.ch",
      };

      try {
        await ses.sendEmail(params).promise();
        console.log("Email sent successfully");
      } catch (err) {
        console.error(err);
      }
      this.resetForm();
      },
      resetForm() {
        (this.firstName = ""),
        (this.email = ""),
        (this.lastName = ""),
        (this.company = ""),
        (this.message = "");
      },
    },
  };
</script>

<style scoped>

.background {
  width: 70%;
  margin: auto;
  padding-top: 7%;
  padding-bottom: 7%;
}

.contact {
  display: flex;
  justify-content: space-between;
}

.contactform h2 {
  color: white;
  font-size: 36px;
  font-weight: 600;
}

.contactform p {
  color: #667085;
  font-size: 20px;
  font-weight: 400;
}

.contactform {
  width: 40%;
}

.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.nameEmail{
  display: flex;
  justify-content: space-between;
}

.form input, .form textarea{
  border: 1px solid #7a7e88;
  background: #242934;
  height: 48px;
  border-radius: 6px;
  margin-bottom: 3%;
  padding: 2%;
}

.label {
  color: #667085;
  margin-bottom: 1%;
}

.form textarea{
  height: 100px;
  padding-top: 1%;
}

.name, .emailIn{
  display: flex;
  flex-direction: column;
  width: 48%;
}

.submit button {
  width: 100%;
  border-radius: 8px;
  height: 48px;
  background: #0041E8;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
}

.form input:focus,
.form textarea:focus,
.form input:not(:placeholder-shown),
.form textarea:not(:placeholder-shown) {
  color: white;
}

.contactimage img {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .background {
    width: 90%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .contactform {
    width: 80% !important;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}

@media screen and (max-width: 1000px) {

  .background {
    width: 90%;
  }

  .contact {
    display: flex;
    flex-direction: column;
  }

  .contactform {
    width: 100%;
    margin-bottom: 5%;
  }

  .contactimage {
    display: none;
  }
}
</style>