import { createRouter, createWebHistory } from "vue-router";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage.vue";
import SpimFashion from "../views/SpimFashion.vue";
import SpimLogistics from "../views/SpimLogistics";
import SpimContactForm from "@/views/SpimContactForm.vue";
import AboutSpim from "@/views/AboutSpim.vue"


const routes = [
  {
    path: "/",
    name: "SpimApp",
    component: HomePage,
    meta: {
      title: "Empowering Business Growth with SPIM | SPIM - Streamline Your Operations",
      metaTags: [
        { name: "description", content: "Empower your business growth with SPIM, the ultimate solution for streamlining operations, managing reports, tracking tasks, and organizing employee information seamlessly. Join many companies benefiting from SPIM's customizable dashboard, report management, task management, employee information management, integration capabilities, access control, notifications, and data security features." },
        { property: "og:description", content: "Empower your business growth with SPIM, the ultimate solution for streamlining operations, managing reports, tracking tasks, and organizing employee information seamlessly. Join many companies benefiting from SPIM's customizable dashboard, report management, task management, employee information management, integration capabilities, access control, notifications, and data security features." }
      ]
    }
  },
  {
    path: "/spim-fashion",
    name: "SpimFashion",
    component: SpimFashion,
    meta: {
      title: "SPIM Fashion Solutions: Inventory Management, Order Tracking, Trend Analysis, and More",
      metaTags: [
        { name: "description", content: "Explore SPIM Fashion solutions for your apparel business. Manage inventory efficiently, track orders accurately, collaborate with vendors seamlessly, analyze market trends, forecast sales, and generate custom reports. Discover how SPIM Fashion empowers fashion businesses with technology, creativity, and innovation." },
        { property: "og:description", content: "Explore SPIM Fashion solutions for your apparel business. Manage inventory efficiently, track orders accurately, collaborate with vendors seamlessly, analyze market trends, forecast sales, and generate custom reports. Discover how SPIM Fashion empowers fashion businesses with technology, creativity, and innovation." }
      ]
    }
  },
  {
    path: "/spim-logistics",
    name: "SpimLogistics",
    component: SpimLogistics,
    meta: {
      title: "SPIM Logistics Solutions: Shipment Tracking, Warehouse Management, Route Optimization, and More",
      metaTags: [
        { name: "description", content: "Discover SPIM Logistics solutions for efficient logistics management. Track shipments in real-time, manage warehouses effectively, optimize delivery routes, forecast demand, analyze real-time analytics, and collaborate with suppliers seamlessly. Learn how SPIM Logistics revolutionizes logistics management with innovation, sustainability, and efficiency." },
        { property: "og:description", content: "Discover SPIM Logistics solutions for efficient logistics management. Track shipments in real-time, manage warehouses effectively, optimize delivery routes, forecast demand, analyze real-time analytics, and collaborate with suppliers seamlessly. Learn how SPIM Logistics revolutionizes logistics management with innovation, sustainability, and efficiency." }
      ]
    }
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: SpimContactForm,
    meta: {
      title: "Contact SPIM - Get in Touch with Our Support Team",
      metaTags: [
        { name: "description", content: "Get in touch with SPIM's support team for inquiries, feedback, or assistance. Our experts are ready to help you with your business needs and provide the best solutions to streamline your operations. Contact us today!" },
        { property: "og:description", content: "Get in touch with SPIM's support team for inquiries, feedback, or assistance. Our experts are ready to help you with your business needs and provide the best solutions to streamline your operations. Contact us today!" }
      ]
    }
  },
  {
    path: "/about-spim",
    name: "AboutSpim",
    component: AboutSpim,
    meta: {
      title: "About SPIM - Learn About Our Vision, Mission, and Team",
      metaTags: [
        { name: "description", content: "Learn about SPIM's vision, mission, and the dedicated team working to revolutionize business operations. Discover our story, values, and commitment to innovation and excellence in providing business solutions." },
        { property: "og:description", content: "Learn about SPIM's vision, mission, and the dedicated team working to revolutionize business operations. Discover our story, values, and commitment to innovation and excellence in providing business solutions." }
      ]
    }
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
