<template>
  <div>
    <fashionIndustry />
    <solutionFashion />
    <customerFashion />
    <ourMission />
    <getInTouchFashion />
  </div>
</template>

<script>
import fashionIndustry from "@/components/spimFashion/fashionIndustry.vue"
import solutionFashion from "@/components/spimFashion/solutionFashion.vue"
import customerFashion from "@/components/spimFashion/customerFashion.vue"
import ourMission from "@/components/spimFashion/ourMission.vue"
import getInTouchFashion from "@/components/spimFashion/getInTouchFashion.vue"

export default {
  name: "SpimFashion",
  metaInfo() {
    return {
      title: "SPIM Fashion Solutions: Inventory Management, Order Tracking, Trend Analysis, and More",
      metaTags: [
        { name: "description", content: "Explore SPIM Fashion solutions for your apparel business. Manage inventory efficiently, track orders accurately, collaborate with vendors seamlessly, analyze market trends, forecast sales, and generate custom reports. Discover how SPIM Fashion empowers fashion businesses with technology, creativity, and innovation." },
        { property: "og:description", content: "Explore SPIM Fashion solutions for your apparel business. Manage inventory efficiently, track orders accurately, collaborate with vendors seamlessly, analyze market trends, forecast sales, and generate custom reports. Discover how SPIM Fashion empowers fashion businesses with technology, creativity, and innovation." }
      ]
    }
  },
  components: {
    fashionIndustry,
    solutionFashion,
    customerFashion,
    ourMission,
    getInTouchFashion,
  }
}
</script>